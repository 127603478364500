@import "base/base";
@import '~bootstrap/scss/bootstrap';
@import "components/components";

body {
  min-height: 100vh;
  width: 100%;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
