// noinspection CssUnknownTarget
@import "~swiper/scss";
@import "~@fancyapps/ui/dist/fancybox/fancybox.css";
@import "../base/base";

.gallery {
  $gallery: &;

  overflow: hidden;
  position: relative;
  height: 500px;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
  }

  &_sm {
    height: 360px;
    margin-right: 0;
    margin-left: 0;

    @include media-breakpoint-up(sm) {
      height: 270px;
    }

    @include media-breakpoint-up(md) {
      height: 330px;
    }

    @include media-breakpoint-up(xl) {
      height: 270px;
    }
  }

  &__content {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    &_rounded {
      border-radius: 1.2rem;
    }
  }

  &__control {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(0 0 0 / 70%);
    z-index: 1;
    color: $text-muted;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;

    $control: &;

    &_prev {
      left: 15px;
    }

    &_next {
      right: 15px;
    }

    #{$gallery}_sm & {
      width: 30px;
      height: 30px;
      color: $white;
      font-size: 1.2rem;
      opacity: 0;
      transition: opacity 300ms;
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    #{$gallery}_sm:hover & {
      opacity: 1;
    }
  }
}
