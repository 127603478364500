@import "../base/base";

.btn {
  &-unstyled {
    --bs-btn-color: #{$white};
    --bs-btn-border-width: 0;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-box-shadow: none;
    --bs-btn-padding-x: 0;
    --bs-btn-padding-y: 0;
    --bs-btn-hover-color: #{shade-color($white, $btn-unstyled-color-hover-amount)};
    --bs-btn-active-color: #{shade-color($white, $btn-unstyled-color-active-amount)};
  }

  svg {
    path {
      fill: var(--bs-btn-color);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--bs-btn-hover-color);
      }
    }
  }

  &:active {
    svg {
      path {
        fill: var(--bs-btn-active-color);
      }
    }
  }
}
